import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

import { Button, Layout, SectionTitle, SEO } from "components";
import { IPageData } from "interfaces";

const publications = ({ data }: IPageData) => {
  const { t } = useTranslation();
  const logoImage = getImage(data.img.childImageSharp);

  return (
    <Layout>
      <SEO
        title={t`config.nav.publications`}
        description={t`informations.paragraph_1`}
      />
      <article className="publications container">
        <SectionTitle title={t`config.title.publications`} />
        <div className="publications__content">
          <section className="content-text">
            <h3 className="publications__block-title">
              {t("publications.presentations")}
            </h3>
            <div className="publications__block">
              <h4>{t("publications.title_1")}</h4>
              <p>{t("publications.paragraph_1")}</p>

              <h4>{t("publications.title_2")}</h4>
              <p>{t("publications.paragraph_2")}</p>
              <p>{t("publications.paragraph_3")}</p>
              <p className="publications__attention-text">
                {t("publications.paragraph_4")}
              </p>
            </div>
            <br />
            <h3 className="publications__block-title">
              {t("publications.publications")}
            </h3>
            <div className="publications__block">
              <p>
                {t("publications.paragraph_5")}{" "}
                <a href={t("publications.link_url")} target="_blank">
                  <strong>{t("publications.link_text")}</strong>
                </a>{" "}
                {t("publications.pkt")}
              </p>
              <p>{t("publications.paragraph_6")}</p>
            </div>
            <Button
              className="publications__btn"
              text="config.button.publications"
              to={t("publications.link_url")}
              newTab
            />
          </section>
          {logoImage && (
            <a
              href={t("publications.link_url")}
              target="_blank"
              className="publications__image-link"
            >
              <GatsbyImage
                className="publications__image"
                alt={data.img.name}
                image={logoImage}
              />
            </a>
          )}
        </div>
      </article>
    </Layout>
  );
};

export default publications;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    img: file(relativeDirectory: { eq: "publications" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
      name
      ext
    }
  }
`;
